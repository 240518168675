import React, { Component } from 'react';
import { MainLayout } from 'src/components/layouts';
import Partners from './Partners';
import PreviousWork from './PreviousWork';
import './OurWork.scss';

export default class OurWork extends Component {
    render(): JSX.Element {
        return (
            <MainLayout className='our-work' light>
                <Partners />
                <PreviousWork />
            </MainLayout>
        );
    }
}
