import React, { Component } from 'react';
import { LogoDefault, LogoDefaultSmall } from 'src/images/Logos';
import MenuItem from './MenuItem';
import './Header.scss';

interface IState {
    menuVisible: boolean;
}

interface IProps {
    overlay?: boolean;
    light?: boolean;
}

export default class Header extends Component<IProps, IState> {
    state: IState = {
        menuVisible: false,
    };

    toggleMenu = (): void => {
        this.setState({ menuVisible: !this.state.menuVisible });
    };

    render(): JSX.Element {
        const { overlay, light } = this.props;
        const { menuVisible } = this.state;

        return (
            <header className={`${overlay ? 'overlay' : ''} ${light ? 'light' : ''}`}>
                <a href='/'>
                    <img src={LogoDefault} alt='SwitchMotionPictures Logo' className='logo-default' />
                    <img src={LogoDefaultSmall} alt='SwitchMotionPictures Logo' className='logo-small' />
                </a>
                <i onClick={this.toggleMenu} className={`fas fa-${menuVisible ? 'times' : 'bars'}`} />
                <nav className={menuVisible ? 'menu-visible' : ''}>
                    <MenuItem toggleMenu={this.toggleMenu} value='About Us' path='/about-us' light={light} />
                    <MenuItem toggleMenu={this.toggleMenu} value='Our Work' path='/our-work' light={light} />
                    <MenuItem toggleMenu={this.toggleMenu} value='Contact Us' path='/contact-us' light={light} />
                </nav>
            </header>
        );
    }
}
