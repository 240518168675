import React, { Component } from 'react';
import './Category.scss';

interface IProps {
    value: string;
    active: boolean;
    onClick: (value: string) => void;
}

export default class Category extends Component<IProps> {
    handleClick = (): void => {
        this.props.onClick(this.props.value);
    };

    render(): JSX.Element {
        const { value, active } = this.props;
        return (
            <div className={`category ${active ? 'active' : ''}`} onClick={this.handleClick}>
                {value}
            </div>
        );
    }
}
