import React, { Component } from 'react';
import Category from './Category';
import './CategorySelector.scss';

interface IProps {
    categories: string[];
    onChange: (category: string, oldCategory?: string) => void;
}

interface IState {
    currentCategory: string;
}

export default class CategorySelector extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentCategory: props.categories[0],
        };
    }

    handleChange = (category: string): void => {
        this.props.onChange(category, this.state.currentCategory);
        this.setState({ currentCategory: category });
    };

    render(): JSX.Element {
        const { categories } = this.props;
        return (
            <div className='category-selector'>
                {categories.map((category: string, index: number) => {
                    return (
                        <Category
                            key={index}
                            value={category}
                            onClick={this.handleChange}
                            active={category === this.state.currentCategory}
                        />
                    );
                })}
            </div>
        );
    }
}
