import React, { Component } from 'react';
import './CompanyInfo.scss';

export default class CompanyInfo extends Component {
    render(): JSX.Element {
        return (
            <div className='company-info'>
                <p>Switch Motion Pictures LTD is a creative content business, specialising in videography.</p>
                <p>
                    The business was established in January 2022 by then-undergraduate students, Bobby and Hana, who
                    have since gained the qualifications necessary to get them to where they are today.
                </p>
                <p>
                    Our team is currently made up of Bobby and Hana themselves. Despite being a team of only two, they
                    work together closely to ensure a personal relationship with all clientele, raising with them to
                    help them receive the content they desire.
                </p>
                <p>
                    Although we are centred around all kinds of videography (promotional, social, weddings and more), we
                    also have experience in photography, graphic design, animation and visual effects (VFX).
                </p>
            </div>
        );
    }
}
