import React, { Component } from 'react';
import './Youtube.scss';

interface IProps {
    video: string;
    width: string;
    height: string;
}

export default class Youtube extends Component<IProps> {
    render(): JSX.Element {
        const { video, width, height } = this.props;

        return (
            <div className='youtube'>
                <iframe
                    width={width}
                    height={height}
                    src={video}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                ></iframe>
            </div>
        );
    }
}
