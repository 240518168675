import React, { Component } from 'react';
import Project, { IProject } from './Project';
import CategorySelector from 'src/components/misc/CategorySelector';
import { smmLogo, smmLogoLarge } from 'src/images/previousWork/smm';
import './PreviousWork.scss';

export const categories = ['All', 'Promotional', 'Graphic Design'] as const;

const projects: IProject[] = [
    {
        category: 'Promotional',
        title: 'SSFC x JADE THIRLWALL x LINES BEHIND ',
        description:
            'Promo video for South Shields F.C. Limited Edition Jersey designed by jade Thirlwall and Lines Behind (James Dixon).',
        video: 'https://www.youtube.com/embed/ZEb-r636mvQ',
    },
    {
        category: 'Promotional',
        title: '360° IGLOO VISION ',
        description: 'The University of Sunderland Digital Incubator Igloo Vision 360° Immersive Cylinder.',
        video: 'https://www.youtube.com/embed/07uTiH6fF1o',
    },
    {
        category: 'Promotional',
        title: 'MATCHDAY HOSPITALITY',
        description: 'South Shields F.C. matchday hospitality experience social promo.',
        video: 'https://www.youtube.com/embed/5AlVcZferXc',
    },
    {
        category: 'Graphic Design',
        title: 'Logo Design',
        description: 'Small More Marketing - Digital marketing company',
        images: [
            { src: smmLogo, alt: 'Small More Media Logo' },
            { src: smmLogoLarge, alt: 'Small More Media Logo - Large' },
        ],
    },
];

interface IState {
    currentCategory: string;
}

export default class PreviousWork extends Component<unknown, IState> {
    state: IState = {
        currentCategory: 'All',
    };

    changeCategory = (category: string): void => {
        this.setState({ currentCategory: category });
    };

    get activeProjects(): IProject[] {
        const { currentCategory } = this.state;
        if (currentCategory === 'All') return projects;

        return projects.filter((project: IProject) => project.category === currentCategory);
    }

    render(): JSX.Element {
        return (
            <div className='previous-work'>
                <CategorySelector categories={categories as unknown as string[]} onChange={this.changeCategory} />
                <div className='projects'>
                    {this.activeProjects.map((project: IProject, index: number) => {
                        return <Project key={index} project={project} />;
                    })}
                </div>
            </div>
        );
    }
}
