import React, { Component } from 'react';
import { Header } from 'src/components/sections';
import './Main.scss';

interface IProps {
    className?: string;
    overlay?: boolean;
    light?: boolean;
}

export default class Main extends Component<IProps> {
    render(): JSX.Element {
        const { className, overlay, light } = this.props;
        return (
            <div className='main-layout'>
                <Header overlay={overlay} light={light} />
                <main className={`${className} ${overlay ? 'overlay-header' : ''}`}>{this.props.children}</main>
            </div>
        );
    }
}
