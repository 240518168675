import React, { Component } from 'react';
import Item, { ICarouselItem } from './Item';
import './Carousel.scss';

interface IProps {
    items: ICarouselItem[];
}

interface IState {
    itemNo: number;
    currentIndex: number;
}

export default class Carousel extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            itemNo: props.items.length,
            currentIndex: 0,
        };
    }

    get currentItems(): ICarouselItem[] {
        const items = JSON.parse(JSON.stringify(this.props.items));
        const { currentIndex } = this.state;

        return items.concat(items.splice(0, currentIndex));
    }

    shiftRight = (): void => {
        const { currentIndex, itemNo } = this.state;
        this.setState({ currentIndex: (currentIndex + 1).mod(itemNo) });
    };

    shiftLeft = (): void => {
        const { currentIndex, itemNo } = this.state;
        this.setState({ currentIndex: (currentIndex - 1).mod(itemNo) });
    };

    render(): JSX.Element {
        console.log(this.state.currentIndex, this.currentItems);

        return (
            <div className='carousel'>
                <div className='icon-container' onClick={this.shiftLeft}>
                    <i className='fa fa-chevron-left' />
                </div>
                <div className='item-list'>
                    {this.currentItems.map((item: ICarouselItem, index: number) => {
                        return <Item key={index} {...item} />;
                    })}
                </div>
                <div className='icon-container' onClick={this.shiftRight}>
                    <i className='fa fa-chevron-right' />
                </div>
            </div>
        );
    }
}
