import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, AboutUs, ContactUs, OurWork } from './pages';

//this is an actual modulo function as js % is merely a remainder operation
Number.prototype.mod = function (modulo: number) {
    return (((this as number) % modulo) + modulo) % modulo;
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/our-work' element={<OurWork />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='*' element={<OurWork />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
