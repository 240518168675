import React, { Component } from 'react';
import './Socials.scss';

export default class Socials extends Component {
    render(): JSX.Element {
        return (
            <div className='socials'>
                <a href='https://www.facebook.com/switchmotionpictures' target='_blank' rel='noreferrer'>
                    <i className='fab fa-facebook' />
                </a>
                <a href='https://twitter.com/switchmotionpic' target='_blank' rel='noreferrer'>
                    <i className='fab fa-twitter' />
                </a>
                <a href='https://www.instagram.com/switchmotionpictures/' target='_blank' rel='noreferrer'>
                    <i className='fab fa-instagram' />
                </a>
                <a href='https://www.tiktok.com/@switchmotionpictures' target='_blank' rel='noreferrer'>
                    <i className='fab fa-tiktok' />
                </a>
                <a
                    href='https://www.youtube.com/channel/UC7k7Y6AwLcua7kS3iHFydPA/videos'
                    target='_blank'
                    rel='noreferrer'
                >
                    <i className='fab fa-youtube' />
                </a>
            </div>
        );
    }
}
