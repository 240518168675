import React, { Component } from 'react';
import './Question.scss';

export interface IQuestion {
    question: string;
    answer: string;
}

interface IState {
    expanded: boolean;
}

export default class Question extends Component<IQuestion, IState> {
    state: IState = {
        expanded: false,
    };

    toggleExpanded = (): void => {
        this.setState({ expanded: !this.state.expanded });
    };

    render(): JSX.Element {
        const { question, answer } = this.props;
        const { expanded } = this.state;

        return (
            <div className={`question ${expanded ? 'expanded' : ''}`}>
                <div className='title' onClick={this.toggleExpanded}>
                    <h3>{question}</h3>
                    <i className={`fa fa-chevron-${expanded ? 'left' : 'down'}`} />
                </div>
                {expanded && <p>{answer}</p>}
            </div>
        );
    }
}
