import React, { Component } from 'react';
import { MainLayout } from 'src/components/layouts';
import ContactForm from 'src/components/form/ContactForm';
import Socials from './Socials';
import FAQs from './FAQs';
import './ContactUs.scss';

const email = 'info@switchmotionpictures.co.uk';
const number = '07516 681 534 ';

export default class ContactUs extends Component {
    render(): JSX.Element {
        return (
            <MainLayout className='contact-us' light>
                <div className='contact-info'>
                    <h1>Get in touch!</h1>
                    <h3>{number}</h3>
                    <h3>{email}</h3>
                </div>
                <ContactForm />
                <Socials />
                <FAQs />
            </MainLayout>
        );
    }
}
