import React, { Component } from 'react';
import { SmpHeroBlack } from 'src/images/showReel';
import './ShowReel.scss';

export default class ShowReel extends Component {
    render(): JSX.Element {
        return (
            <div className='show-reel' style={{ backgroundImage: `url(${SmpHeroBlack})` }}>
                <div className='overlay'>
                    <h1>YOU INSPIRE,</h1>
                    <h1>WE CREATE.</h1>
                    <h1>MAKE THE SWITCH.</h1>
                </div>
            </div>
        );
    }
}
