import React, { Component } from 'react';
import Carousel, { ICarouselItem } from 'src/components/misc/Carousel';
import {
    LittleMix,
    CancerConnections,
    Nike,
    LinesBehind,
    SunderlandUniversity,
    SSFC,
    KitLocker,
} from 'src/images/partners';
import './Partners.scss';

const partnerList: ICarouselItem[] = [
    {
        image: SSFC,
        alt: 'South Shields football club logo',
    },
    {
        image: Nike,
        alt: 'Nike logo',
    },
    {
        image: CancerConnections,
        alt: 'Cancer connections logo',
    },
    {
        image: LinesBehind,
        alt: 'Lines behind logo',
    },
    {
        image: LittleMix,
        alt: 'Little Mix logo',
    },
    {
        image: SunderlandUniversity,
        alt: 'Sunderland University logo',
    },
    {
        image: KitLocker,
        alt: 'KitLocker logo',
    },
];

export default class Partners extends Component {
    render(): JSX.Element {
        return (
            <div className='partners'>
                <h1>People We&apos;ve worked with</h1>
                <Carousel items={partnerList} />
            </div>
        );
    }
}
