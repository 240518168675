import React, { Component } from 'react';
import './TextArea.scss';

interface IProps {
    value: string;
    name: string;
    label: string;
    required?: boolean;
    onChange: (name: string, value: string) => void;
}

export default class TextArea extends Component<IProps> {
    get inputHasValue(): boolean {
        return this.props.value !== '';
    }

    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.props.onChange(e.target.name, e.target.value);
    };

    render(): JSX.Element {
        const { value, name, label, required } = this.props;
        return (
            <div className='textarea-input'>
                <div className='required-container'>{required && <i className='fas fa-asterisk' />}</div>
                <textarea onChange={this.handleChange} value={value} name={name} />
                <label className={this.inputHasValue ? 'input-with-value' : ''}>
                    <span>{label}</span>
                </label>
            </div>
        );
    }
}
