import React, { Component } from 'react';
import { Button } from 'src/components/form/Button';
import { Text, TextArea } from 'src/components/form/inputs';
import emailjs from '@emailjs/browser';
import './ContactForm.scss';

interface IState {
    name: string;
    company: string;
    email: string;
    phone: string;
    message: string;
}

const emptyState = {
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
};

export default class ContactForm extends Component<unknown, IState> {
    state: IState = emptyState;

    submitForm = (): void => {
        this.sendEmail();
    };

    sendEmail = (): void => {
        emailjs.send('default_service', 'contact_form', { ...this.state }, 'TEYlvsJ_CM139EuJL');
        this.setState(emptyState);
    };

    handleInputChange = (name: string, value: string): void => {
        this.setState({ [name]: value } as Record<keyof IState, string>);
    };

    render(): JSX.Element {
        const { name, company, email, phone, message } = this.state;
        return (
            <div className='contact-form'>
                <div className='inputs'>
                    <Text name='name' label='Name' value={name} onChange={this.handleInputChange} required />
                    <Text name='company' label='Company' value={company} onChange={this.handleInputChange} />
                    <Text
                        name='email'
                        label='Email'
                        value={email}
                        onChange={this.handleInputChange}
                        type='email'
                        required
                    />
                    <Text name='phone' label='Phone' value={phone} onChange={this.handleInputChange} />
                    <TextArea
                        name='message'
                        label='Message'
                        value={message}
                        onChange={this.handleInputChange}
                        required
                    />
                </div>
                <Button onClick={this.submitForm} value='Submit'></Button>
            </div>
        );
    }
}
