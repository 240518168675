import React, { Component } from 'react';
import './ImageProject.scss';

interface IProps {
    images: IImage[];
}

export interface IImage {
    src: string;
    alt: string;
}

interface IState {
    curentImage: number;
}

export default class ImageProject extends Component<IProps, IState> {
    state: IState = {
        curentImage: 0,
    };

    nextImage = (): void => {
        this.setState({ curentImage: (this.state.curentImage + 1).mod(2) });
    };

    previousImage = (): void => {
        this.setState({ curentImage: (this.state.curentImage - 1).mod(2) });
    };

    render(): JSX.Element {
        const { src, alt } = this.props.images[this.state.curentImage];

        return (
            <div className='image-project'>
                {this.props.images.length > 1 && <i className='fa fa-chevron-left' onClick={this.previousImage}></i>}
                <img src={src} alt={alt} />
                {this.props.images.length > 1 && <i className='fa fa-chevron-right' onClick={this.nextImage}></i>}
            </div>
        );
    }
}
