import React, { Component } from 'react';
import VideoProject from './Video/VideoProject';
import ImageProject, { IImage } from './Image/ImageProject';
import { categories } from '../PreviousWork';
import './Project.scss';

type Diff<T, U> = T extends U ? never : T;
type TCategory = Diff<typeof categories[number], 'Any'>;

export type IProject = {
    category: TCategory;
    title: string;
    description: string;
} & ({ video: string; images?: never } | { video?: never; images: IImage[] });

interface IProps {
    project: IProject;
}

export default class Project extends Component<IProps> {
    render(): JSX.Element {
        const { title, video, images, description } = this.props.project;

        return (
            <div className='project'>
                {video && <VideoProject video={video} />}
                {images && <ImageProject images={images} />}
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        );
    }
}
