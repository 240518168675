import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './MenuItem.scss';

interface IProps {
    value: string;
    path: string;
    toggleMenu: () => void;
    light?: boolean;
}

export default class MenuItem extends Component<IProps> {
    render(): JSX.Element {
        const { value, path, toggleMenu, light } = this.props;
        return (
            <NavLink
                className={({ isActive }) => `menu-item ${isActive ? ' active' : ''} ${light ? ' light' : ''}`}
                onClick={toggleMenu}
                to={path}
            >
                {value}
            </NavLink>
        );
    }
}
