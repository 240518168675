import React, { Component } from 'react';
import Question, { IQuestion } from './Question';
import './FAQs.scss';

const questions: IQuestion[] = [
    {
        question: 'WHAT SERVICES DO YOU OFFER?',
        answer: 'We offer a variety of services including promotional videos for a business, service or product, event videos (such as weddings, parties), infographics and photography.',
    },
    {
        question: 'HOW LONG WILL IT TAKE?',
        answer: 'It depends on both the type and length of your video - Shorter videos like promos will usually be drafted within 1-3 days after the (final) shooting date, with the final video taking around 1 week in total. Longer videos such as weddings can take between 1-6 months. Photographs may be completed on the same day, or up to 1 week later. However, the above is estimated and advisory - Services can be completed faster or slower, depending on the individual project. ',
    },
    {
        question: 'HOW MUCH SAY DO I HAVE?',
        answer: "Prior to creating your video, we will organise an initial discussion where you will have the opportunity to tell us what you wish to get out of our services. We will try our best to meet these requests, giving you as much creative control as you'd like!",
    },
    {
        question: 'HOW MUCH WILL IT COST? ',
        answer: 'Prices vary from project to project, however, we are committed to providing you with content which is affordable to you. Contact us for individual enquiries.',
    },
];

export default class FAQs extends Component {
    render(): JSX.Element {
        return (
            <div className='faqs'>
                <h1>FAQ&apos;s</h1>
                <div className='questions'>
                    {questions.map((question: IQuestion, index: number) => {
                        return <Question key={index} question={question.question} answer={question.answer} />;
                    })}
                </div>
            </div>
        );
    }
}
