import React, { Component } from 'react';
import { Button } from '../../../components/form/Button';
import './Profile.scss';

export interface IProfile {
    name: string;
    title: string;
    image: string;
    description: string[];
}

interface IProps {
    profile: IProfile;
}

interface IState {
    infoVisible: boolean;
}

export default class Profile extends Component<IProps, IState> {
    state: IState = {
        infoVisible: false,
    };

    toggleInfo = (): void => {
        this.setState({ infoVisible: !this.state.infoVisible });
    };

    handleHoverEnter = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (this.isHovered(e)) {
            this.setState({ infoVisible: true });
        }
    };

    handleHoverLeave = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (this.isHovered(e)) {
            this.setState({ infoVisible: false });
        }
    };

    isHovered = (e: React.MouseEvent<HTMLDivElement>): boolean => {
        const { id } = e.target as HTMLDivElement;
        return id === 'overlay';
    };

    render(): JSX.Element {
        const { name, title, image, description } = this.props.profile;
        const { infoVisible } = this.state;

        return (
            <div className='profile' style={{ backgroundImage: `url(${image})` }}>
                <div
                    onMouseEnter={this.handleHoverEnter}
                    onMouseLeave={this.handleHoverLeave}
                    id='overlay'
                    className={`overlay ${infoVisible ? 'info-visible' : ''}`}
                >
                    {!infoVisible && <Button value={name} onClick={this.toggleInfo} />}
                    {infoVisible && (
                        <div className='info'>
                            <h2>{name}</h2>
                            <h3>{title}</h3>
                            {description.map((desc: string, index) => {
                                return <p key={index}>{desc}</p>;
                            })}
                            <i className='fa fa-times' onClick={this.toggleInfo} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
