import React, { Component } from 'react';
import { MainLayout } from 'src/components/layouts';
import Profile, { IProfile } from 'src/pages/AboutUs/Profile';
import { HanaWalsh, BobbyDent } from 'src/images/team';
import './AboutUs.scss';

const hana: IProfile = {
    name: 'Hana Walsh',
    title: 'Head of Creativity',
    description: [
        'Hana is the co-founder of Switch Motion Pictures LTD. She is the head of creativity, producing all content on behalf of the business.',
        'Prior to establishing the company, Hana gained industry experience such as: NCFE (BFI Film Academy and ScreenSkills) Level 2 Award in Preparing to Work in the Film Industry, Creative Industries Safety Passport approved by IOSH in association with BECTU.',
    ],
    image: HanaWalsh,
};

const bobby: IProfile = {
    name: 'Bobby Dent',
    title: 'Head of Business',
    description: [
        'Bobby is the co-founder of Switch Motion Pictures LTD. Bobby handles the business side of things, making sure everything runs smoothly.',
        'Working for South Shields F.C. as a videographer inspired Bobby to build upon his experience,establishing his own business.',
    ],
    image: BobbyDent,
};

export default class AboutUs extends Component {
    render(): JSX.Element {
        return (
            <MainLayout className='about-us' light>
                <Profile profile={hana} />
                <Profile profile={bobby} />
            </MainLayout>
        );
    }
}
