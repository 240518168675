import React, { Component } from 'react';
import './Button.scss';

interface IProps {
    value: string;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class Button extends Component<IProps> {
    handleClick = (): void => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    render(): JSX.Element {
        return (
            <button onClick={this.handleClick} className='button'>
                {this.props.value}
            </button>
        );
    }
}
