import React, { Component } from 'react';
import YoutubeVideo from 'src/components/misc/Video/Youtube';

export interface IVideoProject {
    video: string;
}

export default class VideoProject extends Component<IVideoProject> {
    render(): JSX.Element {
        return <YoutubeVideo video={this.props.video} width='350' height='196' />;
    }
}
