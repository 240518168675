import React, { Component } from 'react';
import { MainLayout } from 'src/components/layouts';
import ShowReel from './ShowReel';
import CompanyInfo from './CompanyInfo';
import './Home.scss';

export default class Home extends Component {
    render(): JSX.Element {
        return (
            <MainLayout className='home-page' light overlay>
                <ShowReel />
                <CompanyInfo />
            </MainLayout>
        );
    }
}
