import React, { Component } from 'react';
import './Item.scss';

export interface ICarouselItem {
    image: string;
    alt: string;
}

export default class Item extends Component<ICarouselItem> {
    render(): JSX.Element {
        const { image, alt } = this.props;
        return (
            <div className='carousel-item'>
                <img src={image} alt={alt} />
            </div>
        );
    }
}
